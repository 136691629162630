/* eslint-disable max-classes-per-file */
import pathToRegexp from 'path-to-regexp';
import qs from 'qs';

import { assertNever } from '@@util/core/assertNever';

import type { Optionalify } from '@@common-legacy/utils/optionalify';

export type BaseUrlType = string | (() => Promise<string>);
export type ResolveUrlResult<B extends BaseUrlType = BaseUrlType, O extends BaseUrlOptions | undefined = undefined> =
  B extends string ? string
    // relative URLs never need to resolve the baseUrl
    : O extends { absolute: false } ? string
      : Promise<string>;

export interface ResolveUrlOptions<B extends BaseUrlType> {
  readonly baseUrl: B,
  readonly defaultAbsolute: boolean,
  readonly getTrackingContext?: () => TrackingParameters | undefined,
}

interface BaseUrlOptions {
  absolute?: boolean,
}

export type TrackingParameters = {
  utm_medium?: string,
  utm_campaign?: string,
  utm_term?: string,
  utm_content?: string,
}

type UrlOptions<Q = unknown> = BaseUrlOptions & Optionalify<{
  query: Q
  tracking?: TrackingParameters,
}>

type ParamUrlOptions<T, Q> = UrlOptions<Q> & Optionalify<{
  params: T
}>;

export function mergeTrackingParameters(
  base: TrackingParameters | undefined,
  overrides: TrackingParameters | undefined,
): TrackingParameters | undefined {
  if (!base) return overrides;
  if (!overrides) return base;
  return {
    utm_medium: overrides.utm_medium || base.utm_medium,
    utm_campaign: overrides.utm_campaign || base.utm_campaign,
    utm_term: overrides.utm_term || base.utm_term,
    utm_content: overrides.utm_content || base.utm_content,
  };
}

export interface Route {
  readonly pathTemplate: string

  getUrl(options?: UrlOptions): ResolveUrlResult
}

function getUrl<B extends BaseUrlType, O extends UrlOptions>(
  path: string, options: O | undefined, resolveOptions: ResolveUrlOptions<B>,
): ResolveUrlResult<B, O> {
  const result = [];
  let baseUrl: BaseUrlType = process.env.BASE_URL || '';
  if (!baseUrl && (options?.absolute ?? resolveOptions.defaultAbsolute)) {
    baseUrl = resolveOptions.baseUrl;
  }

  result.push(path);

  let querySeparator = '?';

  function appendQuery(query: unknown) {
    const queryString = qs.stringify(query, {
      arrayFormat: 'repeat',
    });
    if (queryString) {
      result.push(querySeparator);
      result.push(queryString);
      querySeparator = '&';
    }
  }

  if (options?.query) {
    appendQuery(options.query);
  }

  const trackingParameters = mergeTrackingParameters(resolveOptions.getTrackingContext?.(), options?.tracking);
  if (trackingParameters) {
    appendQuery({
      utm_source: 'doctaripro',
      ...trackingParameters,
    });
  }

  const suffix = result.join('');
  switch (typeof baseUrl) {
    case 'string': {
      return `${baseUrl}${suffix}` as ResolveUrlResult<B, O>;
    }
    case 'function': {
      const getBaseUrl = baseUrl;
      return (async () => {
        return `${(await getBaseUrl())}${suffix}`;
      })() as ResolveUrlResult<B, O>;
    }
    default: return assertNever(baseUrl);
  }
}

export class NoParamsRoute<Q, B extends BaseUrlType> implements Route {
  readonly pathTemplate: string;

  private readonly urlPath: string;

  constructor(path: string, private readonly options: ResolveUrlOptions<B>) {
    this.pathTemplate = path;
    this.urlPath = path;
  }

  getUrl<O extends UrlOptions<Q>>(options?: O): ResolveUrlResult<B, O> {
    return getUrl(this.urlPath, options, this.options);
  }
}

export class ParamsRoute<P extends Record<string, unknown>, Q, B extends BaseUrlType> implements Route {
  readonly pathTemplate: string;

  private readonly getUrlPathFunction: pathToRegexp.PathFunction;

  constructor(path: string, private readonly options: ResolveUrlOptions<B>) {
    this.pathTemplate = path;
    this.getUrlPathFunction = pathToRegexp.compile(path);
  }

  getUrl<O extends ParamUrlOptions<P, Q>>(options: O): ResolveUrlResult<B, O> {
    return getUrl(this.getUrlPathFunction(options.params, { pretty: true }), options, this.options);
  }
}

export type MyAccountViewType = 'edit' | 'password' | 'notificationSettings' | 'settings' | 'userAccess';
