import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  paper: {
    maxWidth: 475,
    width: '100%',
  },

  dialogTitle: {
    textAlign: 'center',
    padding: 0,
    marginBottom: 28,
    fontSize: 24,
    lineHeight: 1.17,
    letterSpacing: -0.52,
    fontWeight: 600,
  },

  dialogContent: {
    padding: 0,
    marginBottom: 40,

    '&-headline': {
      marginTop: '10px;',
      fontWeight: 600,
    },
  },

  dialogActions: {
    padding: '0 24px 30px',
    gridTemplateColumns: '1fr 1fr',
  },

}), { name: 'browser-info' });
