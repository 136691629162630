import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  pageError: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    margin: 'auto',
  },
  icon: {
    margin: '10px auto',
    fontSize: 120,
    color: theme.palette.primary.main,
  },
}), { name: 'uncaught-ui-error' });
