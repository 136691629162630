import Paper from '@mui/material/Paper';
import classNames from 'classnames';
import React from 'react';

import useStyles from './styles';

import type { PaperProps } from '@mui/material/Paper';

interface Props {
    children?: React.ReactNode;
    backgroundColor?: string;
    borderRadius?: number;
    padding?: number;
    className?: string;
}

const PaperContainer = ({
  children,
  backgroundColor,
  borderRadius,
  padding,
  className,
  ...rest
}: Props & PaperProps): JSX.Element => {
  const classes = useStyles({ backgroundColor, borderRadius, padding });

  return (
    <Paper
      className={ classNames(
        classes.root,
        className,
      ) }
      { ...rest }
    >
      { children }
    </Paper>
  );
};

export default React.memo<Props & PaperProps>(PaperContainer);
