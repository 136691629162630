import makeStyles from '@mui/styles/makeStyles';

import { COLORS } from '@@frontend/styles';

import type { Theme } from '@mui/material/styles';

interface Props {
    borderRadius?: number;
    backgroundColor?: string;
    padding?: number;
    width?: number | string;
}

export default makeStyles<Theme, Props>((theme) => ({
  root: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: (props): number => props.borderRadius || 50,
    borderBottomRightRadius: (props): number => props.borderRadius || 50,
    backgroundColor: (props): string => props.backgroundColor || COLORS.WHITE,
    padding: (props): number | string => props.padding || theme.spacing(4),
    width: (props): number | string => props.width || 'initial',
  },
}), { name: 'paper-container' });
