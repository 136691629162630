import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles/';
import { wrapCreateBrowserRouter } from '@sentry/react';
import { Suspense } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { AppRoot } from '@@frontend/AppRoot';
import ContextProvider from '@@frontend/ReactContext';
import { AppRouterConfig } from '@@frontend/components/AppRouter/AppRouter';
import BrowserInfo from '@@frontend/components/BrowserInfo/BrowserInfo';
import { ErrorElement } from '@@frontend/components/ErrorElement/ErrorElement';
import { GlobalLoader } from '@@frontend/components/GlobalLoader/GlobalLoader';
import { initSentry } from '@@frontend/services/sentry';
import store from '@@frontend/store';

import './App.css';
import './i18n';
import { theme } from './styles';

import '@@frontend/services/googleTagManager';
import '@@frontend/services/hotjar';
import type { Theme } from '@mui/material/styles/';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

initSentry();
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(
  createBrowserRouter,
);
const router = sentryCreateBrowserRouter(AppRouterConfig);

const App = (): JSX.Element => {
  return (
    <ReduxProvider store={ store }>
      <ContextProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={ theme }>
            <BrowserInfo />
            <CssBaseline />
            <ErrorElement />
            <Suspense fallback={ <GlobalLoader /> }>
              <AppRoot>
                <RouterProvider
                  router={ router }
                  fallbackElement={ <GlobalLoader /> }
                />
              </AppRoot>
            </Suspense>
          </ThemeProvider>
        </StyledEngineProvider>
      </ContextProvider>
    </ReduxProvider>
  );
};

export default App;
